<template>
  <div class="admin-feed-block">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['sales']" />

    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`dollar`"
          :label="`<strong>${$locale['total_subscriptions']}</strong>`"
          :rightDesc="
            `<strong skeleton nowrap>$ ${number_format(statistics.totalSubscriptions, 2)}</strong><small skeleton>${$locale['usd']}</small>`
          "
          @click="setQuery({ feed: 'admin-subscriptions', startDate, endDate, period: 'custom' })"
        />
        <LargeButton
          :icon="`dollar`"
          :label="`<strong>${$locale['total_credits']}</strong>`"
          :rightDesc="`<strong skeleton nowrap>$ ${number_format(statistics.totalCredits, 2)}</strong><small skeleton>${$locale['usd']}</small>`"
          @click="setQuery({ admin: 'statistics-bags', startDate, endDate, period: 'custom' })"
        />
      </div>
    </div>

    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`coins`"
          :label="$locale['total_content']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalContent, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          @click="setQuery({ feed: 'admin-statistics-content-details', startDate, endDate, period: 'custom', type: 'unlock' })"
          :desc="
            `<go-progress skeleton progress='${getPercent(statistics.totalContent, statistics.total)}' 
            color='${$locale.colors['content']}'></go-progress>`
          "
        />
        <LargeButton
          :icon="`gift`"
          :label="$locale['total_gifts']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalGifts, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          @click="setQuery({ feed: 'admin-statistics-content-details', startDate, endDate, period: 'custom', type: 'gift' })"
          :desc="
            `<go-progress skeleton progress='${getPercent(statistics.totalGifts, statistics.total)}' 
            color='${$locale.colors['gifts']}'></go-progress>`
          "
        />
        <LargeButton
          :icon="`flower`"
          :label="$locale['total_flowers']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalFlowers, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          @click="setQuery({ feed: 'admin-statistics-content-details', startDate, endDate, period: 'custom', type: 'flower' })"
          :desc="
            `<go-progress skeleton progress='${getPercent(statistics.totalFlowers, statistics.total)}' 
            color='${$locale.colors['flowers']}'></go-progress>`
          "
        />
        <LargeButton
          :icon="`coins`"
          :label="$locale['total_tips']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalTips, 2)}</strong><small skeleton>${$locale['credits']}</small>`"
          @click="setQuery({ feed: 'admin-statistics-content-details', startDate, endDate, period: 'custom', type: 'tip' })"
          :desc="
            `<go-progress skeleton progress='${getPercent(statistics.totalTips, statistics.total)}' 
            color='${$locale.colors['tips']}'></go-progress>`
          "
        />
      </div>
    </div>

    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`" v-if="statistics.total">
      <div class="ReportInfo">
        <AdminStatisticsSalesChart :statistics="statistics" />
      </div>
    </div>
    <Spacer num=".5" />
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsGeneralCard: () => import("./AdminStatisticsGeneralCard.vue"),
    AdminStatisticsSalesChart: () => import("./AdminStatisticsSalesChart.vue"),
    LastPeriodFilter: () => import("../widgets/LastPeriodFilter.vue"),
  },
  data: function() {
    return {
      loading: true,
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function() {
      const { period, startDate, endDate } = this.$route.query;
      let query = `?period=${period}`;
      query += `&startDate=${startDate}&endDate=${endDate}`;
      return query;
    },
    getStatistics: async function() {
      this.isLoading(true);
      try {
        const query = this.getQuery();
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/statistics-sales${query}`);
        this.$store.commit("setAdminSalesStatistics", { statistics: response.data?.statistics });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    getPercent: function(mount, total) {
      return Math.round((mount / total) * 100);
    },
  },
  mounted: function() {
    this.isLoading(true);
    setTimeout(() => {
      this.getStatistics();
    }, 1000);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    statistics: function() {
      return this.$store.getters.admin.sales;
    },
  },
  watch: {
    "$route.query": function() {
      this.getStatistics();
    },
  },
};
</script>
